/* src/App.css */
.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

/* src/App.css */
html, body {
  font-family: 'Roboto', sans-serif;
  margin: 0; /* Remove default body margin */
  padding: 0; /* Remove default body padding */
  background-color: #f5f5dc;
}

h1 {
  margin: 0;
}

form {
  background-color: #fff;
  padding: 20px;
  margin: 16px auto;
  max-width: 800px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

form div {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 1rem;
}

button {
  background-color: #00796b;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #005f56;
  transform: translateY(-2px);
}

.recipe-card:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}


@media (max-width: 600px) {
  .recipe-list {
    grid-template-columns: 1fr;
  }
}
