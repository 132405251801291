/* AuthenticatorModal.css */

.custom-link-button {
    color: #0d6efd;
}

.custom-link-button:hover {
    color: #0b5ed7;
    background-color: transparent;
}

.custom-link-button:focus,
.custom-link-button:active {
    color: #0b5ed7;
    background-color: transparent;
}

/* AuthenticatorModal.css */

/* Target the 'Back to Sign In' button */
.authenticator-dialog .amplify-authenticator .amplify-button[data-testid="back-to-sign-in-button"] {
    /* Your custom styles */
    color: #0d6efd;
    background-color: transparent;
    border: none;
}

/* Hover state */
.authenticator-dialog .amplify-authenticator .amplify-button[data-testid="back-to-sign-in-button"]:hover {
    color: #0b5ed7;
    background-color: transparent;
}

