/* src/components/RecipeDetails.css */
.recipe-details {
    max-width: 800px;
    margin: 20px auto;
    padding: 16px;
    background-color: #fff;
    border-radius: 12px;
}

.back-button {
    display: inline-block;
    margin-bottom: 16px;
    text-decoration: none;
    color: #00796b;
    font-weight: bold;
    padding: 8px 12px;
}

.back-button:hover {
    text-decoration: underline;
}

.recipe-name {
    margin-top: 0;
    font-size: 2rem;
    color: #333;
    text-align: center; /* Center the recipe name */
}

.recipe-author {
    color: #777;
    font-size: 1rem;
    text-align: center; /* Center the author name */
    margin-bottom: 16px;
}

.recipe-details-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    margin: 16px 0;
}

.recipe-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center; /* Center meta information */
    margin-bottom: 16px;
}

.recipe-meta div {
    background-color: #f5f5f5;
    padding: 8px 12px;
    border-radius: 8px;
}

.recipe-rating {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the rating */
    margin: 16px 0;
}

.recipe-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 24px;
}

/* New styles for sections */
.recipe-section {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 24px; /* Keep margin for spacing */
    min-width: 50%;
}

.recipe-section:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.section-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 16px;
    text-align: left;
    padding-left: 10px;

}

.ingredients-list,
.equipment-list,
.steps-list,
.tips-list,
.dietary-list,
.allergens-list {
    list-style-type: none;
    padding-left: 10px;
}

.ingredients-list li,
.equipment-list li,
.steps-list li,
.tips-list li,
.dietary-list li,
.allergens-list li {
    padding: 10px 0; /* Spacing between items */
    border-bottom: 1px solid #e0e0e0; /* Add light line between items */
    font-size: 1rem; /* Adjust as needed */
    line-height: 1.5; /* For better readability */
}

.ingredients-list li:last-child,
.equipment-list li:last-child,
.steps-list li:last-child,
.tips-list li:last-child,
.dietary-list li:last-child,
.allergens-list li:last-child {
    border-bottom: none;
}

.steps-list {
    list-style-type: decimal; /* Numbered list for steps */
}

/* Ensure that Steps and Tips sections are block elements */
.steps-section,
.tips-section {
    display: block;
    width: 95%;
}

/* Remove any unintended flex properties from Steps and Tips */
.steps-section,
.tips-section {
    /* If any flex properties are inherited, override them */
    flex: none;
    margin-bottom: 24px;
}

.caloric-breakdown {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the caloric breakdown */
    gap: 16px;
}

.caloric-breakdown li {
    background-color: #e0f7fa;
    color: #00796b;
    padding: 8px 12px;
    border-radius: 16px;
    font-size: 0.9rem;
}

.rating-stars {
    display: flex;
    margin-right: 8px;
}

.ingredients-equipment-container,
.dietary-allergens-container{
    background-color: transparent !important; /* Force transparent background */
    min-width: 80%;
}

@media (min-width: 768px) {
    .ingredients-equipment-container,
    .dietary-allergens-container {
        display: flex;
        gap: 24px;
    }

    .ingredients-section,
    .equipment-section,
    .dietary-section,
    .allergens-section {
        flex: 1;
        margin-bottom: 0;
    }
}

/* Responsive adjustments */
@media (max-width: 767px) {
    .ingredients-equipment-container,
    .dietary-allergens-container {
        display: block;
    }
}

@media (max-width: 600px) {
    .recipe-meta {
        flex-direction: column;
        align-items: center;
    }

    .caloric-breakdown {
        flex-direction: column;
        align-items: center;
    }

    .recipe-name {
        font-size: 1.5rem;
    }

    .section-title {
        font-size: 1.25rem;
    }

    .recipe-description {
        font-size: 1rem;
    }
}
