/* src/pages/SearchPage.css */

/* Main container */
.search-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    background-color: #f9f9f9;
    min-height: 100vh;
}

/* Page title */
.search-page-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 24px;
    color: #333;
    text-align: center;
}

/* No results message */
.no-results {
    text-align: center;
    margin-top: 24px;
    font-size: 1.2rem;
    color: #777;
}

/* Button to create a recipe */
.create-recipe-button {
    background-color: #00796b;
    color: #fff;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 16px;
    transition: background-color 0.3s ease;
}

.create-recipe-button:hover {
    background-color: #005a4c;
}

/* Recipe grid styling (reuse the grid class from RecipeCard) */
.recipe-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    width: 100%;
    max-width: 1200px;
    justify-content: center;
    margin-top: 24px;
}

/* Load more button */
.load-more-button {
    background-color: #00796b;
    color: #fff;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 16px;
    transition: background-color 0.3s ease;
}

.load-more-button:hover {
    background-color: #005a4c;
}

/* Loading spinner */
.loading-spinner {
    margin-top: 50px;
    font-size: 1.5rem;
    color: #00796b;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .search-page-title {
        font-size: 1.5rem;
    }

    .create-recipe-button,
    .load-more-button {
        font-size: 0.9rem;
        padding: 8px 16px;
    }
}
