.root {
    max-width: 70%; /* Make the paper a bit wider */
    margin: 0 auto;
    padding: 40px 90px;
    z-index: 1;
}

.background {
    background-image: url('../../../public/images/kitchen-background.png'); /* Relative to the public folder */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 95%; /* Reduce width to create space on both sides */
    margin: 0 auto; /* Center the background horizontally */
    height: 70vh; /* Cut off the image height to 80% of the viewport */
    overflow: hidden; /* Hide the part of the image that extends beyond the container */
    border-radius: 20px; /* Add rounded corners */
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: flex-start; /* Start content at the top */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.overlay {
    position: relative;
    z-index: 2;
    margin-top: 50px; /* Add space at the top to push the content down */
    width: 100%;
}

.suggestionsList {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: white;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 3;
}
