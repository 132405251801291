/* src/components/RecipeCard.css */
.recipe-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px; /* Set the max width of each card */
    width: 100%; /* Ensure cards are responsive */
}

.recipe-card-link {
    text-decoration: none;
    color: inherit;
}

.recipe-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.recipe-content {
    padding: 16px;
}

.recipe-calories {
    margin-top: 8px;
    font-size: 0.9rem;
    color: #555;
}

.recipe-title {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.recipe-author {
    margin: 4px 0;
    color: #777;
    font-size: 0.9rem;
}

.recipe-rating {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.rating-stars {
    display: flex;
    margin-right: 8px;
}

.recipe-details {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 8px 0;
}

.recipe-details div {
    background-color: #f5f5f5;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9rem;
}

.recipe-tags,
.recipe-dietary {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 8px 0;
}

.recipe-tag,
.dietary-tag {
    background-color: #e0f7fa;
    color: #00796b;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 0.8rem;
}

/* New grid container for recipe cards */
.recipe-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust to fit 5 cards */
    gap: 16px;
    justify-content: center;
    margin: 16px 0;
    padding: 0 16px;
}
