/* Common Styles */
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f5f5dc;
}

.header-nav {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
}

.header-logo {
    grid-column: 2 / 3;
    justify-self: center;
    margin: 0 8% 0 10%;
}

.header-hamburger {
    display: none;
}

.header-menu {
    grid-column: 1 / 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.header-links,
.header-actions {
    display: flex;
    align-items: center;
    gap: 60px;
}

.header-links {
    flex: 1;
    justify-content: flex-end;
}

.header-actions {
    flex: 1;
    justify-content: flex-start;
}

/* Dropdown Styles */
.header-link-group {
    position: relative;
}

.header-link,
.header-action {
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: #333;
    text-decoration: none;
}

.header-link:hover {
    color: #000;
}

.dropdown {
    display: block;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f5f5dc;
    padding: 10px;
    list-style: none;
    z-index: 1;
    min-width: 150px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transform: translateY(-10px);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    margin-top: 3px;
}

.header-link-group:hover .dropdown,
.header-link-group .dropdown:hover {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.dropdown li {
    padding: 5px 10px;
}

.dropdown li + li {
    border-top: 1px solid #e0e0e0;
}

.dropdown li a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 5px 0;
}

.dropdown li a:hover {
    color: #000;
}

.header-search-icon {
    cursor: pointer;
}

.search-icon {
    font-size: 18px;
    color: #333;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .header-nav {
        grid-template-columns: auto 1fr auto;
    }

    .header-hamburger {
        display: flex;
        align-items: center;
        font-size: 24px;
        width: 90vw;
        justify-content: space-between;
    }

    .header-hamburger-menu {
        cursor: pointer;
    }

    .header-menu {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #f5f5dc;
        z-index: 1;
    }

    .header-menu.open {
        display: flex;
    }

    .header-links,
    .header-actions {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 10px 20px;
    }

    .header-links {
        margin-bottom: 20px;
    }

    .header-link-group {
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
    }

    .header-link {
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-link .arrow-down {
        transform: rotate(0deg);
        transition: transform 0.3s ease;
    }

    .header-link-group.active .header-link .arrow-down {
        transform: rotate(180deg);
    }

    .dropdown {
        position: static;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: none;
        transition: none;
        padding: 0;
        margin: 0;
        display: none;
    }

    .header-link-group.active .dropdown {
        display: block;
    }

    .dropdown li {
        padding: 5px 20px;
    }

    .dropdown li + li {
        border-top: none;
    }

    .header-actions .header-action {
        width: 100%;
        padding: 10px 0;
        text-align: left;
    }

    .header-search-icon {
        padding: 10px 0;
        width: 100%;
    }
}
