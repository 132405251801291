.footer {
    background-color: #333;
    color: #fff;
    padding: 10px;
    width: 98%; /* Keep the reduced width */
    margin: 0 auto 20px auto; /* Center the footer */
    border-radius: 20px; /* Rounded top edges */
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.footer-section {
    width: 50%; /* Full width for better control */
}

.footer-section h4 {
    font-size: 20px;
    margin-bottom: 0;
    text-align: center;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Ensure columns wrap when screen gets smaller */
    margin: 10px 0;
}

.footer-column {
    flex: 1 1 100px; /* Each column will take up equal space with a minimum width of 200px */
    margin: 10px;
}

.footer-column h5 {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
}

.footer-column ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
}

.footer-column ul li {
    margin-bottom: 5px;
}

.footer-column ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-column ul li a:hover {
    text-decoration: underline;
}

.footer-middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-top: 20px;
    flex-wrap: wrap; /* Make sure footer bottom items wrap too */
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    margin-top: 30px;
    flex-wrap: wrap; /* Make sure footer bottom items wrap too */
}

.social-media {
    text-align: left;
    flex-grow: 1;
}

.social-icons {
    display: flex;
    justify-content: left;
    gap: 10px;
}

.social-icons a {
    color: #fff;
    font-size: 20px;
}

.terms-conditions {
    text-align: center;
    flex-grow: 1;
}

.footer-meta {
    text-align: right;
    flex-grow: 1;
}

.footer-meta .languages a {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
}

.footer-meta .languages a:hover {
    text-decoration: underline;
}

.terms-conditions a {
    color: #fff;
    text-decoration: none;
}

.terms-conditions a:hover {
    text-decoration: underline;
}

.terms-conditions a:hover {
    text-decoration: underline;
}

.languages a {
    margin: 0 8px 0 8px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .footer-links {
        flex-direction: column; /* Stack columns vertically on smaller screens */
        align-items: center;
    }

    .footer-column {
        width: 100%; /* Full width for columns on small screens */
        text-align: center;
    }

    .footer-middle {
        flex-direction: column; /* Stack bottom section items vertically */
        align-items: center;
    }

    .footer-meta {
        text-align: center; /* Center language links */
    }
}
